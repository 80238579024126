<template>
  <div class="box-wrap">
    <div class="content-box">
      <div class="title-item">
        <img src="@/assets/img/result/success-icon.png" alt="" />
        <p>{{type === 'receive' ? "领取成功" : "预约成功"}}</p>
      </div>
      <div v-if="type === 'receive'" class="text-item">
        <p>我们将安排专属客户经理联系您，为您领取</p>
        <p>领取福利，并安排<span>专车接送</span>看墓</p>
      </div>
        <div v-else class="text-item">
        <p>我们将安排专属客户经理联系您，为您提供</p>
        <p>咨询，并安排<span>专车接送</span>看墓</p>
      </div>
      <p class="note-text">请您注意接听来电~</p>
      <div class="qrCode-box">
        <img class="qrCode-img" src="@/assets/img/result/qrcode.png" alt="" />
        <div class="sava-item">
          <p>长按图片保存二维码图片</p>
          <img src="@/assets/img/result/hand.png" alt="" />
        </div>
      </div>
      <div class="note-item">
        <p>微信扫描上方二维码，添加顾问企业微信，</p>
        <p>获取更多购墓相关资料</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "recResult",
  data() {
    return {
      type: ''
    }
  },
  mounted() {
    let { type } = this.$route.query;
    this.type = type
    console.log(type);
    if (type === "receive") {
      document.title = "领取成功";
    } else {
      document.title = "预约成功";
    }
  },
  methods: {
    savaClick() {
      let Url =
        "https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/qrcode_411214562835251238.jpg";
      const a = document.createElement("a"); // 创建a标签
      a.setAttribute("download", ""); // download属性
      a.setAttribute("href", Url); // href链接
      a.click();
    },
  },
};
</script>
<style lang="scss" scoped>
.box-wrap {
  padding: 0.3rem;
  background: url("~@/assets/img/result/bg.png") no-repeat center top;
  background-size: 100% 100%;
  .content-box {
    height: 10.52rem;
    background: url("~@/assets/img/result/content-bg.png") no-repeat center;
    background-size: 100%;
    text-align: center;
    .title-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 0.36rem;
      img {
        width: 0.6rem;
        height: 0.6rem;
      }
      p {
        font-size: 0.36rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #951e15;
        line-height: 0.5rem;
        padding-top: 0.16rem;
      }
    }
    .text-item {
      padding-top: 0.28rem;
      p {
        font-size: 0.32rem;
        font-weight: 400;
        color: #333333;
        line-height: 0.45rem;
        span {
          color: #951e15;
        }
      }
    }
    .note-text {
      padding-top: 0.16rem;
      font-size: 0.32rem;
      font-weight: 500;
      color: #333333;
      line-height: 0.45rem;
    }
    .qrCode-box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-top: 1.2rem;
      .qrCode-img {
        width: 2.64rem;
      }
      .sava-item {
        display: flex;
        align-items: center;
        padding-top: 0.2rem;
        p {
          font-size: 0.28rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #0379fb;
          line-height: 0.4rem;
        }
        img {
          width: 0.35rem;
          height: 0.42rem;
          margin-left: 0.08rem;
          animation: handAni 3s ease-in-out infinite;
        }
      }
    }
    .note-item {
      padding-top: 0.66rem;
      p {
        font-size: 0.32rem;
        font-weight: 400;
        color: #666666;
        line-height: 0.46rem;
        &:last-child {
          color: #951E15;
        }
      }
    }
  }
}
@keyframes handAni {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-0.3rem, -0.3rem);
  }
  100% {
    transform: translate(0, 0);
  }
}
</style>